@import "../../color-filters";
@import "../../animations";

$whiteFilter: invert(100%) sepia(100%) saturate(0%) hue-rotate(102deg) brightness(102%) contrast(102%);
$colorFilter: invert(21%) sepia(62%) saturate(3205%) hue-rotate(310deg) brightness(80%) contrast(126%);

body{
  &[data-mode="dark"] {
    .detail-header{
        .vertical-icons{
            color: var(--white);
        }

      .close-icon{
        filter: $whiteFilter;
      }

    }

    .detailed-container{
      .contact-section{
        .contact-section-item{
          &:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, .25);
          }
          .section-actions{
            .action-icon{
              a{
                color: var(--white);
              }
              color: var(--white);
              border: 1px solid rgba(255, 255, 255, .6);
            }
          }
        }
      }
    }

  }

  &[data-mode="light"] {
    .detail-header{
      .vertical-icons{
        color: var(--color-main-normal);
      }


       .close-icon{
          filter: $colorFilter;
        }
    }

    .section-actions{
      .action-icon{
        border: 1px solid var(--black);
      }
    }

    .detailed-container{
      .contact-section{
        .contact-section-item{
          &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, .25);
          }
          .section-actions{
            .action-icon{
              color: var(--black);
              border: 1px solid rgba(0, 0, 0, .6);
            }
          }
        }
      }
    }
  }
}

.detailed-container{
  .detail-header{
    .close-details{
      margin-left: auto;
    }

    .vertical-icons{
      color: var(--white);
      width: 24px;
      height: 24px;
    }



    .close-icon{
      filter: $colorFilter;
      width: 18px;
      height: 18px;
    }

    .close-icon,.vertical-icons{
      transition: ease-in-out .3s;
      cursor: pointer;
      &:hover{
        opacity: .6;
      }
    }

      .vertical-icons{
        width: 20px;
        height: 20px;
    }
  }

  .image-section{
    padding: 1.2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .contact-image{
      margin-right: 0;
      width: 60px;
      height: 60px;

      .contact-icon{
        width: 20px;
        height: 20px;
      }
    }

    .item-status{
      width: 13px;
      height: 13px;
      top: 0;
      right: 6px;
    }

    img{
      width: 60px;
      height: 60px;
      object-fit: contain;
    }

  }

  .contact-name,.contact-address{
    margin: 0 auto;
    text-align: center;
  }

  .contact-name{
    font-size: 1rem;
    width: calc(75% - 1.5rem);
    line-height: 2.8rem;
    font-weight: 700;
  }

  .contact-address{
    font-size: .9rem;
    opacity: .8;
  }

  .contact-section{
    margin-top: 3.5rem;

    .contact-section-item{
      padding: 1.2rem 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, .25);
      }
      .section-data{
        padding-right: 1.2rem;
        .data-icon{
          width: 24px;
          height: 24px;
          margin-right: 1.2rem;
          color: var(--color-main-normal);
        }

        .data-list{
          .data-value{
            line-height: 2rem;
            font-size: .95rem;
            white-space: initial;
          }

          .data-label{
            font-size: .8rem;
            opacity: .7;
          }
        }
      }

      .section-actions{
        .action-icon{
          color: var(--black);
          &:not(:last-child){
            margin-right: .75rem;
          }

          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border: 1px solid var(--black);
          border-radius: 50%;
          transition: ease-in-out .3s;
          cursor: pointer;


          &:hover{
            opacity: .6;
          }

          .icon{
            width: 13px;
            height: 13px;

            &.error-icon{
              width: 16px;
              height: 16px;
              color: var(--color-error);
            }

            &.chat-icon{
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .favorite-section{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 1.5rem 0;
      flex-direction: column;

      .favorite-icon{
        width: 28px;
        height: 28px;
        cursor: pointer;

        &.star-empty{
          transition: ease-in-out .3s;
          &:hover{
            filter: $baseFilter;
          }
        }

        &.star-filled{
          color: var(--color-main-normal);
          animation: scaleItem .6s;
        }

      }

      .favorite-label{
        margin-top: .3rem;
        font-size: .85rem;
      }
    }
  }
}

.error-section{
  margin: 3.5rem auto;
}

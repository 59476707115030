@import "../color-filters";
body{
  &[data-mode="dark"] {
    .slider-feedback{
      background: var(--black);
    }
  }

  &[data-mode="light"] {
    .slider-feedback{
      background: var(--white);
    }
  }
}
.slider-feedback{
  position: fixed;
  top: 135px;
  right: 0;
  display: flex;
  align-items: center;
  width: 250px;
  border-radius: 12px 0 0 12px;
  transition: ease-in-out .5s;

  &.open{
    transform: translateX(0px);
  }

  &.success{
    box-shadow: 0 0 4px 2px rgba(7, 198, 141, .3);
  }

  &.error{
    box-shadow: 0 0 4px 2px rgba(255, 0, 0, .3);
  }

  &.closed{
    transform: translateX(300px);
  }

  .icon-section{
    padding: .65rem .65rem;
    .icon-holder{
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.error{
        border: 1px solid var(--color-error);
       svg{
         filter: $redFilter;
       }
      }

      &.success{
        border: 1px solid var(--color-success);

        svg{
          filter: $greenFilter;
        }
      }

      svg{
        width: 12px;
        height: 12px;
      }
    }
  }

  .feedback-text{
    font-size: .8rem;
    line-height: 1rem;
    white-space: initial;
    padding:  .8rem;
  }
}

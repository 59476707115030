
body{
  &[data-mode="dark"] {
    .detailed-container{
      box-shadow: 0 0 5px 3px rgba(119, 1, 58, 0.5);

      .auth-section{
        .input-control{
          label{
            color: var(--white);
            opacity: .6;
          }
        }
        .return-link{
          a{
            color: var(--white);
          }
        }
      }
    }
  }

  &[data-mode="light"] {
    .detailed-container{
        .verification-section{
          .code-resend{
            color: var(--color-main-normal);
          }
        }

    }
  }

  &[data-mode="light"] {
    .detailed-container{
      .auth-section{
        .input-control{
          label{
            color: var(--color-main-dark);
          }
        }
      }
    }
  }
}

.auth-container{
  width: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;

  &.login-container{
    align-items: center;
    justify-content: center;
  }

  &.register-container{
    padding: 60px 0;
  }


  .detailed-container{
    margin: 0 auto;
    .auth-header{
      font-size: 1.35rem;
      text-align: center;
      line-height: 2.5rem;
      font-weight: 600;
    }

    .auth-section{
      margin-top: 2.5rem;

      &.login-section{
        .input-control{
          input{
            padding: 9px 36px;
          }
        }

        .button-container{
          margin-top: 3.5rem;
        }
      }

      .input-control{
          label{
            color: var(--color-main-dark);
          }
      }


      .button-container{
        width: 100%;
      }

      .button{
        width: 100%;
        padding: 6px 22px;
        box-shadow: none;
        font-size: .95rem;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          filter: none;
          position: static;
          top: unset;
          transform: translate(0, 0);
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: 20px;
        }
      }

      &.register-section{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .half-width-control{

          @media screen and (min-width: 650px) {
            width: calc(50% - 1.5rem);
          }

          @media screen and (max-width: 650px) {
            width: 100%;
          }

        }

        .button-container{
          margin-top: 2rem;
        }
      }

      .return-link{
        font-size: .9rem;
        line-height: 2rem;
        margin-top: .8rem;

        a{
          font-weight: 600;
          text-decoration: none;
          opacity: .7;
          transition: ease-in-out .2s;
          margin-left: .6rem;

          &:hover{
            opacity: 1;
            text-decoration: underline;
          }
        }
      }
    }

    .verification-section{
      .section-header{
        display: flex;
        align-items: center;

        .header-icon{
          width: 18px;
          height: 18px;
          transition: ease-in-out .2s;
          cursor: pointer;

          &:hover{
            transform: scale(1.2);
          }
        }

        .header-text{
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 2.4rem;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .section-desc{
        width: calc(90% - 1.5rem);
        line-height: 1.5rem;
        margin: 0 auto;
        text-align: center;
        font-size: .9rem;
        opacity: .8;
      }

      .input-control{
        margin: 1.5rem 0;
      }


      .code-resend{
        font-size: .8rem;
        margin: 1rem auto 0;
        cursor: pointer;
        width: fit-content;
        width: -moz-fit-content;

        &.faded{
          opacity: .5;
        }

        &:hover{
          opacity: .7;
        }
      }
    }
  }
}



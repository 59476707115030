body{
  &[data-mode="light"] {
   .media-placeholder{
     background: #f2f2f2;

     svg{
       color: rgba(0, 0, 0, .6);
     }
   }
  }

  &[data-mode="dark"] {
    .media-placeholder{
      background: rgba(0, 0, 0, .75);
    }
  }
}

.media-placeholder{
  width: 240px;
  background: rgba(0, 0, 0, .75);
  border-radius: 12px;
  opacity: .5;
  display: flex;

  &:not(.voice-note) {
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-main-normal);
    height: 120px;
  }

  &.voice-note{
    min-height: 45px;
    flex-direction: column;
    justify-content: center;
    padding: .3rem .6rem;
    border: 1px solid rgba(193, 0, 96, .6);

  }

  svg{
    font-size: 32px;
    color: var(--white);
    opacity: .6;
  }
}


body{
  &[data-mode="dark"] {
    .search-bar-container{
      &.fixed-container{
        background: var(--black);
      }
    }

    .contact-list{
      .data-list-item{
        .contact-group{
          background: transparent;
          border: 1px solid rgba(224, 26, 122, .2);
          .contact-item{

            &:not(:last-child){
              border-bottom: 1px solid rgba(224, 26, 122, .3);
            }
            .contact-image{
              .contact-icon{
                color: var(--white);
              }
            }

            .contact-data{
              .contact-name{
                color: rgba(255, 255, 255, .8);
              }
            }
          }
        }
      }
    }

    .favorite-icon{
      filter: none;
      color: var(--white);
      opacity: .8;
      animation: scaleItem .6s;
    }
  }

  &[data-mode="light"] {
    .search-bar-container{
      &.fixed-container{
        background: var(--white);
      }


    }

    .contact-list{
      /*box-shadow: 0 0 4px 6px rgba(0, 0, 0, .09);
      border-radius: 24px;
      background: var(--white);*/
      .data-list-item{
        .contact-group{
          background: var(--white);
          .contact-item{

            &:not(:last-child){
              border-bottom: 1px solid rgba(0, 0, 0, .15);
            }
            .contact-image{
              .contact-icon{
                color: var(--color-main-dark);
              }
            }


            .contact-data{
              .contact-name{
                color: rgba(0, 0, 0, .8);
              }
            }
          }
        }
      }
    }

  }
}

.contact-list-container{
  display: flex;
  padding-top: 1.5rem;
  min-height: 100vh;
  overflow-y: auto;
  .favorite-icon{
    width: 20px;
    height: 20px;

    color: var(--color-warning);
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }

  .list-content{
    transition: all .2s;
    @media screen and (max-width: 1023px) {
      width: 100%;
    }

    @media screen and (min-width: 1024px) {
      &.details-open{
        width: calc(100% - 550px);
        margin-left: 0;

        .search-bar{
          width: calc(85% - 3.5rem);
        }
      }

      &.details-closed{
        width: calc(100% - 450px);
        margin-left: auto;
        margin-right: auto;

        .search-bar{
          width: calc(60% - 3.5rem);
        }
      }
    }

    &.details-closed{
      .initials-list{
        @media screen and (max-width: 1023px) {
          right: 2.5%;
        }

        @media screen and (min-width: 1024px) and (max-width: 1200px)  {
          right: 22.5%;
        }

        @media screen and (min-width: 1201px) and (max-width: 1499px){
          right: 18.5%;
        }

        @media screen and (min-width: 1500px) and (max-width: 1799px){
          right: 16.5%;
        }

        @media screen and (min-width: 1800px){
          right: 14.5%;
        }
      }
    }

    &.details-open{
      .initials-list{
        @media screen and (max-width: 1023px) {
          right: 2.5%;
        }

        @media screen and (min-width: 1024px) and (max-width: 1099px)  {
          left: 45%;
        }
        @media screen and (min-width: 1100px) and (max-width: 1200px) {
          left: 48%;
        }

        @media screen and (min-width: 1201px) and (max-width: 1299px){
          left: 52.5%;
        }

        @media screen and (min-width: 1300px) and (max-width: 1419px) {
          left: 56%;
        }

        @media screen and (min-width: 1420px) and (max-width: 1600px) {
          left: 60%;
        }

        @media screen and (min-width: 1500px) and (max-width: 1799px){
          left: 61.5%;
        }

        @media screen and (min-width: 1800px){
          left: 67%;
        }
      }
    }

    @media screen and (min-width: 1620px) {
      .contact-list{
        .data-list-item{
          max-width: 85%;
        }
      }
    }
  }

  .contact-details{
    transition: ease .3s;
    height: 300px;

    @media screen and (max-width: 1023px) {
      display: none;
    }

    @media screen and (min-width: 1024px) {
      width: 500px;
      position: fixed;
      top: 150px;
      right: 50px;
      margin-top: 5rem;
    }

    &.scroll-fixed{
      top: 60px;
    }

    .detailed-container{
      width: 100%;
      margin: 0;
    }


    &.details-closed{
      transform: translateY(80px);
      opacity: 0;
      display: none;
    }

    &.details-open{
      transform: translateY(0);
      opacity: 1;
      animation: slideUp .3s;

      @media screen and (min-width: 1024px) {
        display: block;
      }

      @media screen and (max-width: 1023px) {
        display: none;
      }

    }
  }



  .progress-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 450px;
    @media screen and (min-width: 750px) {
      width: 300px;
    }

    @media screen and (max-width: 749px) {
      width: calc(90% - 2rem);
    }
  }

  .contact-list{
    margin: 2.5rem 0;
    position: relative;

    @media screen and (min-width: 750px) {
      padding: 2.5rem;
    }
    /*max-height: calc(100vh - 200px);
    overflow-y: auto;
    white-space: nowrap;*/

    .contact-count{
      font-size: 1rem;
      margin-bottom: 1rem;
      font-weight: 700;
    }

    .data-list-item{
      animation: slideUp .3s;
      &:not(:last-child){
        margin-bottom: 2rem;
      }

      .initial-section{
        padding: .5rem .75rem;
      }
      .contact-group{
        padding: 1rem .75rem;
        background: var(--white);
        border-radius: 20px;
        .contact-item{
          padding: .75rem 0;
          &:not(:last-child){
            border-bottom: 1px solid rgba(0, 0, 0, .15);
          }

          .contact-data{
            flex-grow: 100;
            white-space: initial;

            .contact-name{
              width: fit-content;
              width: -moz-fit-content;
              font-size: 1rem;
              line-height: 2rem;
              color: var(--color-main-dark);
              transition: ease-in-out .3s;
              cursor: pointer;

              span{
                &:not(:last-child){
                  margin-right: .3rem;
                }
               }
              &:hover{
                color: var(--color-main-dark);
                opacity: .7;
              }
            }
          }
          .favorite-icon{
            margin-left: auto;
            margin-right: 1.5rem;
          }
        }
      }
    }
  }


  .initials-list{
    position: fixed;
    top: 225px;
    width: fit-content;
    width: -moz-fit-content;

    span{
      display: block;
      width: fit-content;
      width: -moz-fit-content;
      font-size: .75rem;
      font-weight: 700;
      transition: ease-in-out .3s;
      cursor: pointer;
      margin-left: 0;
      margin-right: 0;

      &:hover{
        color: var(--color-main-normal);
        opacity: .7;
        transform: scale(1.5);
      }

      &:not(:last-child) {
        margin-bottom: .6rem;
      }

      &.active{
        transform: scale(2);
        color: var(--color-main-normal);
      }
    }
  }


  .no-contacts{
    padding: 1.5rem 0;
    text-align: center;
    font-size: 1rem;
  }
}


.error-section{
  display: flex;
  align-items: center;
  margin: 5rem auto;
  font-size: 1rem;
  width: fit-content;
  width: -moz-fit-content;

  .error-icon{
    margin-right: .6rem;
    width: 22px;
    height: 22px;
  }
}

.duration-slider{
  width: 100%;
  position: relative;
  margin-top: -6px;

  input{
    width: 100%;
    height: 3px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: rgba(255, 255, 255, .6);
    border-radius: 6px;
    transition: all .2s;
    outline: 0;
    cursor: pointer;

    &::-moz-range-thumb{
      width: 17px;
      height: 17px;
      background: var(--color-main-light);
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: all .3s;
      border: 1px solid var(--color-main-light);
    }

    &::-webkit-slider-thumb{
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 17px;
      height: 17px;
      background: var(--color-main-light);
      border-radius: 50%;
      transition: all .3s;
      cursor: pointer;
      position: relative;
      z-index: 2;
    }

    &:hover{
      & + .position-indicator{
        background: var(--color-main-dark);
      }

      &::-webkit-slider-thumb{
        box-shadow: 0 0 4px 4px rgba(224, 26, 122, .27), 0 0 4px 8px rgba(224, 26, 122, .4);
      }
    }
  }
}

.position-indicator{
  position: absolute;
  top: 65%;
  transform: translateY(-65%);
  left: 0;
  width: 0;
  height: 3.8px;
  background: var(--color-main-normal);
  z-index: 1;
}

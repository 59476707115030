.contact-image{
  width: 35px;
  height: 35px;
  background: rgba(224, 26, 122, .5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;

  .contact-icon{
    width: 15px;
    height: 15px;
    color: var(--white);
  }
}
.checkbox{
  display: none;

  &:checked + .slider {
    background: rgba(193, 0, 96, .7);
    .switch{
      transform: translate(20px, -50%);
    }
  }
}

.slider{
  background: rgba(193, 0, 96, .32);
  width: 35px;
  height: 6px;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all .75s;

  .switch{
    position: absolute;
    width: 15px;
    height: 15px;
    background: var(--color-main-dark);
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border-radius: 50%;
    transition: all .5s;
    border: 4px solid transparent;

    &:hover{
      cursor: pointer;
      border: 4px solid var(--color-main-dark);
      transform: translateY(-50%) scale(1.05);
    }
  }
}


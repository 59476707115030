@import "../../animations";

body{
  &[data-mode="light"] {
    .list-container{
      background: var(--white);
      border-top: 1px solid rgba(119, 1, 58, .29);
      border-right: 1px solid rgba(119, 1, 58, .29);

      .pre-loader{
        .spinner-item{
          div{
            border-top: 2px solid var(--color-main-dark) !important;
          }
        }
      }

      .space-list{
        .space-item{
          color: var(--black);
          &:not(:last-child){
            border-bottom: 1px solid rgba(0, 0, 0, .09);
          }
        }
      }
    }
  }

  &[data-mode="dark"] {
    .list-container{
      .space-list{
        .space-item{
          color: var(--white);
          &:not(:last-child){
            border-bottom: 1px solid rgba(255, 255, 255, .09);
          }
        }
      }
    }
  }
}

.list-container{
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;

  .pre-loader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2rem 0;

    .loading-text{
      font-size: .85rem;
      margin-bottom: .7rem;
      padding: 0 .8rem;
      white-space: initial;

      &.error{
        color: var(--color-error);
        opacity: .7;
      }
    }
  }

  .list-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 150px;
    padding: 1.2rem 1.5rem;
    border-bottom: 1px solid rgba(119, 1, 58, .2);

    .list-heading{
      font-size: 1.3rem;
      font-weight: 700;
    }
  }

  .space-list{
    height: 100%;
    overflow-y: auto;
    white-space: nowrap;

    .space-item{
      padding: 1.2rem 1.5rem;
      display: flex;
      transition: ease .3s;
      animation: slideUp .3s;
      text-decoration: none;
      color: var(--black);
      &:not(:last-child){
        border-bottom: 1px solid rgba(0, 0, 0, .09);
      }

      &:hover{
        background: rgba(224, 26, 122, .09);
        cursor: pointer;
      }
    }
    .space-user{
      flex-grow: 100;
      max-width: 85%;
      .data-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .4rem;

        .username{
          max-width: calc(95% - 1.5rem);
          font-size: .9rem;
          font-weight: 600;
        }

        .timestamp{
          font-size: .75rem;
          opacity: .75;
        }
      }

      .last-message{
        font-size: .7rem;
        line-height: 1.2rem;
        opacity: .7;
        width: calc(100% - 3.5rem);
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        svg{
          font-size: 18px;
          margin-right: .6rem;
        }

      }

      .unread-notifs{
        color: var(--color-main-light);
        font-size: .75rem;
        font-weight: 700;
        text-align: center;
      }
    }
  }
}

.cropped-text{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100%);
}

body{
  &[data-mode="light"] {
    .profile-view-container{
      background: var(--white);

      .update-form{
        .field-group{
          .group-data{
            .input-control{
              label{
                color: var(--black);
              }
            }
          }
        }
      }
    }

    .safe-bar{
      background: var(--white);

      .safe-content{
        .button{
          &.button-transparent{
            border: 1px solid var(--color-main-dark);
            color: var(--color-main-dark);
          }
        }
      }
    }
  }

  &[data-mode="dark"] {
    .safe-bar{
      background: var(--black);
    }
  }

}

.profile-view-container{

  &.container{
    @media screen and (max-width: 600px) {
      width: calc(100% - 1rem);
      padding: 2.2rem 1rem;
    }

    @media screen and (min-width: 750px) and (max-width: 1199px){
      width: calc(90% - 2.5rem);
    }

  }
  .load-holder{
    width: 100%;
    height: calc(100vh - 4.4rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .load-error{
      font-size: .75rem;
      span{
        color: var(--color-error);
        font-weight: 600;
        transition: ease-in-out .3s;
        margin-left: .6rem;

        &:hover{
          cursor: pointer;
          opacity: .7;
        }
      }
    }
  }
  .header-section{
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(193, 0, 96, .3);
    .profile-image-container{
      width: fit-content;
      width: -moz-fit-content;
      margin: 0 auto 1.5rem;
      position: relative;

      .space-image{
        .contact-image, .user-image{
          width: 70px;
          height: 70px;
        }
        .contact-image{
          margin-right: 0;

          .contact-icon{
            width: 25px;
            height: 25px;
          }
        }

        .item-status{
          right: 10px;
          width: 13px;
          height: 13px;
        }
      }

      .image-picker-section{
        position: absolute;
        bottom: 0;
        right: 4px;
        width: 24px;
        height: 24px;

        label{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background: var(--black);
          color: var(--white);
          border-radius: 50%;
          border: 1px solid var(--color-main-dark);
          transition: ease-in-out .3s;

          &:hover{
            cursor: pointer;
            opacity: .7;
          }

          svg{
            font-size: 12px;
          }
        }
        input{
          &[type="file"] {
            display: none;
          }
        }
      }
    }

    .user-details{
      text-align: center;

      .user-name{
        font-weight: 700;
        font-size: 1.35rem;
        text-align: center;
      }

      .phone{
        margin: .7rem 0;
        font-size: .95rem;
        opacity: .8;
        line-height: 1.5rem;
      }

      .address{
        font-size: 1rem;
        opacity: .7;
        line-height: 1.75rem;
      }

      .email{
        margin-top: .5rem;
        line-height: 1.75rem;
      }
    }
  }

  .edit-section{
    padding: 2.2rem 1.5rem;
    .edit-header{
      font-size: 1.3rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
  }

  .update-form{
    .field-group{
      margin-bottom: 2.2rem;



      header{
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }


      .group-data{
        flex-wrap: wrap;

        .input-control{

          @media screen and (min-width: 750px) {
            &.half-width{
              width: calc(50% - 1.5rem);
            }
          }

          @media screen and (max-width: 749px) {
            &.half-width{
              width: 100%;
            }
          }



          label{
            color: var(--white);
            opacity: .7;
          }
        }
      }
    }

    .button-container{
      max-width: 800px;
      margin: 2.5rem auto 0;
      button{
        width: 100%;
        font-weight: 500;
        font-size: .95rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.safe-bar{
  position: fixed;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  transition: ease-in-out .5s;

  @media screen and (min-width: 651px) {
    top: 66px;
  }

  @media screen and (min-width: 421px) and (max-width: 650px) {
    top: 80px;
  }

  @media screen and (max-width: 420px) {
    top: 125px;
  }

  &.open{
    transform: translateY(0);
  }

  &.closed{
    transform: translateY(-200%);
  }

  .safe-content{
    justify-content: flex-end;

    .button{
      padding: .5rem 1rem;
      font-size: .75rem;
      font-weight: 500;

      &:not(:last-child) {
        margin-right: .8rem;
      }
    }
  }
}

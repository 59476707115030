@import '../color-filters';

.picture-element{
  width: 450px;
  height: 350px;
  overflow: hidden;
  position: relative;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: ease-in-out .3s;

    &:hover{
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

.picture-modal{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .95);
  transition: ease-in-out .3s;
  padding-top: 70px;
  overflow-y: auto;
  z-index: 99;

  &.view{
    transform:translate(0, 0) scale(1);
    position: fixed;
    top: 0;
    left: 0;
  }

  &.hide{
    transform: translate(-50%, -50%) scale(0);
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .modal-header{
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 0;

    .header-item{
      font-size: 20px;
      color: var(--white);
      filter: $whiteFilter;
      cursor: pointer;
      transition: ease-in-out .3s;
      &:not(:last-child) {
        margin-right: .75rem;
      }

      &.download{
        &:hover{
          opacity: .5;
        }
      }

      &.close{
        &:hover{
          transform: scale(1.2);
        }
      }
    }
  }

  .media-element{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding-bottom: 20px;
    .media-item{
      @media screen and (min-width: 650px) {
        width: 600px;
        height: 600px;
      }

      @media screen and (max-width: 649px) {
        width: 90%;
        height: 450px;
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@import "../animations";

.video-player{
  width: 500px;
  height: 350px;
  position: relative;
  overflow-y: hidden;
  video{
    width: 100%;
    height: 100%;
  }

  .video-controls{
    position: absolute;
    bottom: -2px;
    left: 0;
    padding: .3rem .6rem;
    background: #1e0310;
    animation: translateUpwards .4s;
    width: 100%;

    .control-row{
      .video-duration{
        font-size: .65rem;
        color: var(--white);
      }

      .range-row{
        .duration-slider{
          .position-indicator{
            bottom: 2px;
            transform: translateY(-2px);
          }
        }
      }
    }

    .lower-row{
      margin-top: .8rem;
      align-items: center;
      .play-toggle{
        font-size: 24px;
        color: var(--white);
        transition: ease-in-out .3s;
        cursor: pointer;

        &:hover{
          transform: scale(1.2);
        }
      }
      .play-toggle, .fullscreen-toggle{
        transition: ease-in-out .3s;
        cursor: pointer;

        &:hover{
          transform: scale(1.2);
        }
      }
      .fullscreen-toggle{
        font-size: 16px;
        color: var(--white);
      }

      .settings-section{
        position: relative;

        .gear-icon{
          cursor: pointer;
          transition: ease-in-out .3s;
          color: var(--white);
          &:hover{
            opacity: .7;
          }
        }

        .data-list, .option-list{
          position: absolute;
          width: 135px;
          list-style: none;
          background: var(--black);
          color: var(--white);
          padding-left: 0;
        }

        .option-list{
          top: -250%;
          z-index: 99;
          font-size: .85rem;
          left: 0;
          li{
            padding: .3rem .6rem;
            transition: ease-in-out .3s;
            cursor: pointer;
            position: relative;

            &.switch{
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            &:hover{
              background: #f2f2f2;
              color: var(--black);

              .data-list{
                display: block;
              }
            }

            .data-list{
              left: 100%;
              bottom: 0;
              display: none;
            }
          }
        }
      }
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;600;700&display=swap');
@import "animations";

:root{
    --bg-color: #050002;
    --bg-color-rgb: rgb(5, 0, 2);
    --color-main-light: #e01a7a;
    --color-main-light-rgb: rgb(224, 26, 122);
    --color-main-normal: #c10060;
    --color-main-normal-rgb: rgb(193, 0, 96);
    --color-main-dark: #77013a;
    --color-main-dark-rgb: rgb(119, 1, 58);
    --black: #000000;
    --white: #ffffff;
    --color-warning: #ffc126;
    --color-error: #ff0000;
    --color-success: #07c68d;
    --color-active: #2d896e;
    --color-active-light: #10d348;
    --color-bg-dark: #050002;
    --color-bg-light: #f2f2f2;
    --blue-alternate: #003763;
    --blue-alternate-rgb: rgb(0, 55, 99);
}

$pageWidth: calc(100% - 5rem);
$pageWidthTab: calc(100% - 2.5rem);
$pageWidthMobile: calc(100% - 1.5rem);

@import "color-filters";

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body{
  font-family: 'Mukta', sans-serif;
  transition: ease .3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &[data-mode="light"]{
    background: var(--color-bg-light);
    color: var(--black);

    a{
      color: var(--color-main-dark);
    }

    .navigation{
/*      box-shadow: 0 4px 2px 2px rgba(0, 0, 0, .06);*/
      background: var(--white);

      .navigation-main{
        .app-name{
          a{
            color: var(--black);
          }
        }
      }

      .slider{
        .mode-switcher{
          $darkShadow:  rgba(255, 193, 38, .59);
          $darkShadowHover:  rgba(255, 193, 38, .89);
          box-shadow: 0 0 6px 2px $darkShadow;

          &:hover{
            animation: flickerLight 2.5s linear infinite;
          }

          @keyframes flickerLight{
            0%{box-shadow: 0 0 6px 2px $darkShadow;}
            16.6%{box-shadow: 0 0 10px 2px $darkShadowHover;}
            33.3%{box-shadow: 0 0 14px 2px $darkShadowHover;}
            50%{box-shadow: 0 0 6px 2px $darkShadow;}
            66.6%{box-shadow: 0 0 10px 2px $darkShadowHover;}
            83%{box-shadow: 0 0 14px 2px $darkShadowHover;}
            100%{box-shadow: 0 0 6px 2px $darkShadow;}
          }
        }
      }

      .nav-items, .mobile-nav{
        .nav-list{
          li{
            .user-section{


              .user-dropdown{
                .user-dropdown-content{
                  background: var(--white);

                  &::after{
                    border-bottom: 8px solid var(--white);
                  }
                }
              }
            }
          }
        }
      }

    }

    .input-control{
      .control-icon{
        color: var(--color-main-dark);
        filter: $baseFilter;
      }
      input, textarea{
        color: var(--black);
        background: var(--white);
        border: 1px solid  rgba(193, 0, 96, 0.35);
        //box-shadow: 0 0 5px rgba(119, 1, 58, 0.5);


        &.alternate{
          border-bottom: 1px solid rgba(119, 1, 58, .8);
          color: var(--white);

          &:focus{
            border-bottom: 1px solid var(--color-main-light);
          }
        }

        &::placeholder{
          color: rgba(0, 0, 0, .8);
        }

        &:focus{
          border: 1px solid var(--color-main-light);
          box-shadow: 0 0 6px rgba(119, 1, 58, 0.5);
        }
      }
    }

    .detailed-container{
      background: var(--white);
    }

    .feedback-container{

      &.error{
       background: rgba(255, 0, 0, .3);
      }

      &.success{
        background: rgba(7, 198, 141, .3);
        color: var(--color-success);
      }
    }
  }

  &[data-mode="dark"]{
    background: var(--color-bg-dark);
    color: var(--white);

    a{
      color: var(--color-main-normal);
    }

    .navigation{
      /*box-shadow: 0 8px 2px rgba(224, 26, 122, .15);*/
      background: var(--black);

      .navigation-main{
        .app-name{
          a{
            color: var(--white);
          }
        }
      }

      .slider{
        .mode-switcher{
          $darkShadow: rgba(255, 255, 255, .59);
          $darkShadowHover: rgba(255, 255, 255, .89);
          box-shadow: 0 0 6px 2px $darkShadow;

          &:hover{
            animation: flicker 2.5s linear infinite;
          }

          @keyframes flicker{
            0%{box-shadow: 0 0 6px 2px $darkShadow;}
            16.6%{box-shadow: 0 0 10px 2px $darkShadowHover;}
            33.3%{box-shadow: 0 0 14px 2px $darkShadowHover;}
            50%{box-shadow: 0 0 6px 2px $darkShadow;}
            66.6%{box-shadow: 0 0 10px 2px $darkShadowHover;}
            83%{box-shadow: 0 0 14px 2px $darkShadowHover;}
            100%{box-shadow: 0 0 6px 2px $darkShadow;}
          }
        }
      }

      .nav-items, .mobile-nav{
        .nav-list{
          li{
            .user-section{
              .user-dropdown{
                .user-dropdown-content{
                  background: var(--black);

                  &::after{
                    border-bottom: 8px solid var(--black);
                  }

                  li {
                    a{
                      color: var(--white);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .input-control{
      .control-icon{
        color: var(--white);
        filter: $whiteFilter;
      }
      input, textarea{
        color: var(--white);
        background: rgba(119, 1, 58, 0.09);
        border: 1px solid  rgba(119, 1, 58, 0.15);

        &.alternate{
          border-bottom: 1px solid rgba(119, 1, 58, .6);
          color: var(--white);

          &:focus{
            border-bottom: 1px solid var(--color-main-light);
          }
        }

        &::placeholder{
          color: rgba(255, 255, 255, .45);
        }

        &:focus{
          border: 1px solid var(--color-main-light);
        }
      }


    }

  }



}

a{
  transition: ease-in-out .3s;
  &:hover{
    opacity: .6;
  }
}

.flexbox{
  display: flex;
}

.align-center{
  align-items: center;
}

.justify-center{
  justify-content: center;
}

.space-between{
  justify-content: space-between;
}

.column{
  flex-direction: column;
}

.centered{
  margin-left: auto;
  margin-right: auto;
}

.relative-item{
  position: relative;
}

.absolute-item{
  position: absolute;
}

.fixed{
  position: fixed;
}

.top-left{
  top: 0;
  left: 0;
}

.top-right{
  top: 0;
  right: 0;
}

.bottom-left{
  bottom: 0;
  left: 0;
}

.bottom-right{
  bottom: 0;
  right: 0;
}

.absolute-center-vertical{
  top: 50%;
  transform: translateY(-50%);
}

.absolute-center-horizontal{
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-container{
  .button:not(.button-transparent){
    svg{
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
    }
  }

  svg{
    width: 13px;
    height: 13px;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    right: 16px;
  }
}

.button{
  padding: 12px 22px;
  font-size: .8rem;
  font-weight: 700;
  color: var(--white);
  font-family: inherit;
  position: relative;
  opacity: .9;
  border-radius: 8px;
  transition: ease-in-out .3s;

  &:hover{
    opacity: .7;
    cursor: pointer;
  }

  &:disabled{
    opacity: .4;
  }
}

.button-danger{
  border: 1px solid rgba(255, 0, 0, .5);
  color: rgba(255, 0, 0, .8);
  background: transparent;

  &:hover{
    background: rgba(255, 0, 0, .5);
    color: var(--white);
  }
}

.button-transparent{
  background: transparent;

}

.blue-alternate{
  background: var(--blue-alternate);
  border: 1px solid var(--blue-alternate);
}

.light-transparent{
  border: 1px solid var(--color-main-light);
  color: var(--color-main-light);

  &:hover{
    background: var(--color-main-light);
    color: var(--white);
  }
}

.main-transparent{
  border: 1px solid var(--color-main-normal);
  color: var(--color-main-normal);

  &:hover{
    background: var(--color-main-normal);
    color: var(--white);
  }
}

.theme-transparent{
  border: 1px solid var(--color-main-dark);
  color: var(--color-main-dark);

  &:hover{
    background: var(--color-main-dark);
    color: var(--white);
  }
}

.dark-transparent{
  border: 1px solid var(--black);
  color: var(--black);

  &:hover{
    background: var(--black);
    color: var(--white);
  }
}

.white-transparent{
  border: 1px solid var(--white);
  color: var(--white);

  &:hover{
    transform: scale(1.05);
  }
}

.button-light{
  background: var(--color-main-light);
  border: 1px solid var(--color-main-light);
  color: var(--white);
}

.button-regular{
  background: var(--color-main-normal);
  border: 1px solid var(--color-main-normal);
  box-shadow: 0 4px 8px  rgba(7, 89, 155, .4);
}

.button-main{
  background: var(--color-main-dark);
  border: 1px solid var(--color-main-dark);
}

.button-dark{
  background: var(--black);
  border: 1px solid var(--black);
}

.input-control{
  position: relative;
  width: 100%;

  .control-icon{
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    color: var(--black);
  }
  label{
    font-size: .9rem;
    font-weight: 700;
    color: var(--color-main-dark);
  }

  input, textarea{
    padding: 9px 27px;
    font-size: .9rem;
    width: 100%;
    border-radius: 8px;
    transition: ease-in-out .2s;
    margin-top:3px;

    &.alternate{
      border-right-style: none;
      border-top-style: none;
      border-left-style: none;
      background: transparent;
      border-radius: 0;

      &:focus{
        border-right-style: none;
        border-top-style: none;
        border-left-style: none;
      }
    }

    &.input-error{
      border: 1px solid rgba(255, 0, 0, .8);
      animation: vibrate .6s;
      background: rgba(255, 0, 0, .13) !important;
    }

    &.input-success{
      border: 1px solid rgba(7, 198, 141, .2) !important;
      background: rgba(7, 198, 141, .13) !important;
    }



    &:focus{
      transition: .3s ease;
      outline: 0;
    }
  }

  textarea{
    height: 180px;
    font-family: 'Amiko', sans-serif;
  }

  .input-error{
    color: rgba(255, 0, 0, .6);
    animation: vibrate .6s;
  }

  &:not(:last-child){
    margin-bottom: 1rem;
  }
  .error-text{
    font-size: .8rem;
    font-weight: 600;
    margin-top: .6rem;
    color: var(--color-error);
    opacity: .8;
    line-height: 1rem;
  }



  @keyframes vibrate {
    0%{ transform: translateX(0px);}
    25%{ transform: translateX(-7px);}
    50%{ transform: translateX(7px); }
    75%{ transform: translateX(-7px); }
    100%{ transform: translateX(0px); }
  }

  @-webkit-keyframes vibrate {
    0%{ transform: translateX(0px);}
    25%{ transform: translateX(-7px);}
    50%{ transform: translateX(7px); }
    75%{ transform: translateX(-7px); }
    100%{ transform: translateX(0px); }
  }

  @-moz-keyframes vibrate {
    0%{ transform: translateX(0px);}
    25%{ transform: translateX(-7px);}
    50%{ transform: translateX(7px); }
    75%{ transform: translateX(-7px); }
    100%{ transform: translateX(0px); }
  }

  @-o-keyframes vibrate {
    0%{ transform: translateX(0px);}
    25%{ transform: translateX(-7px);}
    50%{ transform: translateX(7px); }
    75%{ transform: translateX(-7px); }
    100%{ transform: translateX(0px); }
  }
}

.half-width{
  width: 50%;
}

.full-width{
  width: 100%;
}

.full-height{
  height: 100%;
}

.grid{
  display: grid;
  justify-content: space-between;
  grid-gap: 25px 0;
}

.double-grid{
  grid-template-columns: repeat(2, 1fr);
}

.triple-grid{
  grid-template-columns: repeat(3, 1fr);
}

.quadruple-grid{
  grid-template-columns: repeat(4, 1fr);
}

.rounded{
  border-radius: 50%;
}

.link-item{
  font-size: .75rem;
  color: var(--color-main-dark);
  text-decoration: underline;
}

.text-center{
  text-align: center;
}

.margin-top-xs{
  margin-top: .6rem;
}

.margin-left-xs{
  margin-left: .6rem;
}

.font-sm{
  font-size: .75rem;
}

.font-md{
  font-size: 1.1rem;
}

.container{
  padding: 2.2rem 1.5rem;
  border-radius: 16px;
  max-width: 1100px;

  @media screen and (min-width: 750px) and (max-width: 1199px) {
    width: calc(85% - 4.5rem);
  }

  @media screen and (max-width: 749px) {
    width: calc(95% - 2rem);
  }
}
.container-sm{
  @media screen and (min-width: 1200px) {
    width: calc(42.5% - 10rem);
  }
}

.container-md{
  @media screen and (min-width: 1200px) {
    width: calc(65% - 5rem);
  }
}

.container-lg{
  @media screen and (min-width: 1200px) {
    width: calc(85% - 10rem);
  }
}

.hidden{
  display: none !important;
}


.page-container{


  @media screen and (max-width: 420px) {
    padding-top: 130px;
  }

  @media screen and (min-width: 421px) {
    padding-top: 80px;
  }
  .main-content{
    width: calc(100% - 275px);
    margin-left: auto;
    margin-right: 1.5rem;
    padding-top: 85px;
    position: relative;

    .content-container{
      width: calc(95% - 2rem);
      margin: 0 auto;
    }
  }
}

a.active, .color-gradient{
  background: linear-gradient(to right, rgba(255, 255, 255, .75), rgba(0, 139, 160, .8));
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.modal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, .75);
  width: 100%;
  height: 100%;

  .modal-content{
    background: var(--black);
    border-radius: 16px;
    padding: 1.5rem 1.1rem;
    position: relative;
    color: var(--white);
    margin: 100px auto;
    animation: slideDown .5s;
    box-shadow: 2px 2px 2px 6px rgba(0, 139, 160, .25);

    .close-button{
      position: absolute;
      top: -17.5px;
      right: -8px;
      width: 35px;
      height: 35px;
      background: var(--color-bg);
      color: var(--white);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: ease-in-out .3s;

      &:hover{
        cursor: pointer;
        background: var(--color-main-normal);
      }

      .close-icon{
        width: 18px;
        height: 18px;
      }
    }
  }

  @keyframes slideDown {
    from{ margin-top: 0 }
    to{ margin-top: 100px }
  }
}

.tooltip{
  position: absolute;
  max-width:400px;
  border-radius: 8px;
  font-size: .7rem;
  text-align: center;
  color: var(--white);
  left: 50%;
  transform: translateX(-50%);
  animation: fadeIntoView .3s;

  &.regular{
    background:  rgba(0, 55, 99, .45);

  }

  &.error{
    background: rgba(255, 0, 0, .5);
  }

  &.above{
    top: -20px;

    &.error{
      .content{
        &::before{
          border-top: 5px solid rgba(255, 0, 0, .5);
          top: 100%;
        }
      }
    }

    &.regular{
      .content{
        &::before{
          border-top: 5px solid  rgba(0, 55, 99, .45);
          top: 100%;
        }
      }
    }
  }

  &.below{
    top: 105%;

    .content{
      &::before{
        top: -5px;
      }
    }

    &.error{
      .content{
        &::before{
          border-bottom: 5px solid rgba(255, 0, 0, .3);
        }
      }
    }

    &.regular{
      .content{
        &::before{
          border-bottom: 5px solid rgba(0, 55, 99, .45);
        }
      }
    }
  }

  .content{
    width: 100%;
    padding: .4rem .6rem;
    position: relative;

    &::before{
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      left: 50%;
      transform: translateX(-50%);
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }

  @keyframes fadeIntoView {
    from{opacity: 0;}
    to{opacity: 1}
  }
}


.error-section{
  width: 100%;
  padding: .8rem 1.35rem;
  background: rgba(255, 0, 0, .3);
  font-size: .75rem;
  color: rgba(255, 255, 255, .85);
  border-radius: 10px;
  margin: 1.5rem auto  0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 500px;


  .error-icon{
    width: 20px;
    height: 20px;
    margin-right: .6rem;
  }


}

.page-width {

  @media screen and (min-width: 1024px) {
    width: $pageWidth;
  }

  @media screen and (min-width: 750px) and (max-width: 1024px) {
    width: $pageWidthTab;
  }

  @media screen and (max-width: 749px) {
    width: 100%;
    padding: 0 1.5rem;
  }



}

.navigation{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  @media screen and (min-width: 651px) {
    padding: .8rem 0;
  }

  @media screen and (max-width: 650px) {
    padding: 1.35rem 0;
  }


  .navigation-main{
    .app-name{
      font-size: 1.5rem;
      font-weight: 600;

      a{
        text-decoration: none;
      }
    }

    .nav-items{

      @media screen and (max-width: 420px) {
        .nav-list{
          display: none;
        }
      }
      .toggle-section{
        @media screen and (min-width: 650px) {
          margin-left: 2.5rem;
        }

        @media screen and (max-width: 649px) {
          margin-left: 2rem;
        }


        .checkbox{
          &:checked {
            & + .slider{
              .switch{
                transform: translate(25px, -50%);
              }
            }
          }
        }

        .slider{
          width: 40px;
          .switch{
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
              width: 12px;
              height: 12px;

              &.moon{
                filter: $whiteFilter;
              }

              &.sun{
                filter: $yellowFilter;
              }
            }
          }
        }
      }
    }
  }

  .nav-list{
    padding-left: 0;
    li{
      display: inline-block;
      vertical-align: middle;
      font-size: .95rem;

      &.main-nav{
        @media screen and (max-width: 650px){
          span{
            display: none;
          }
        }
      }

      a{
        text-decoration: none;
        font-size: .95rem;
        position: relative;

        .message-count{
          position: absolute;
          top: -2px;
          left: -6px;
          background: var(--color-main-dark);
          color: var(--white);
          font-size: .7rem;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          span{
            display: block !important;
          }
        }


        @media screen and (min-width: 651px) {
          .nav-icon{
            width: 18px;
            height: 18px;
            margin-right: .6rem;
          }
        }

        @media screen and (max-width: 650px){
          .nav-icon {
            width: 20px;
            height: 20px;
          }
        }
      }


      @media screen and (min-width: 651px) {
        &:not(:first-child) {
          padding-left: 2.2rem;
        }
      }
      @media screen and (max-width: 650px) {
        &:not(:first-child) {
          padding-left: 2rem;
        }
      }

      .user-section-data{
        cursor: pointer;
        .username-items{
          font-size: .7rem;
          text-align: center;
          padding-left: .4rem;
          @media screen and (min-width: 651px) {
            .username{
              display: block;
            }

            .initials{
              display: none;
            }
          }

          @media screen and (max-width: 650px){
            .username{
              display: none;
            }

            .initials{
              display:block;
              text-transform: uppercase;
              font-size: .85rem;
            }
          }
        }
      }

      .user-section{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: ease-in-out .3s;


        img{
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
        }

        &:hover .contact-image{
          opacity: .7;
        }

        &.open{
          .toggle-icon{
            transform: rotate(180deg);
          }

          .user-dropdown{
            display: block;
          }
        }

        .contact-image{
          margin-right: 0;
          width: 25px;
          height: 25px;

          svg{
            width: 12px;
            height: 12px;
          }
        }

        .toggle-icon{
          width: 11px;
          height: 11px;
          margin-left: .3rem;
          transition: ease .3s;
        }

        .user-dropdown{
          position: absolute;
          top: 120%;
          left: 50%;
          transform: translateX(-50%);
          max-width: 200px;
          box-shadow: 0 0 3px 2px rgba(193, 0, 96, .40);
          display: none;
        }

        .user-dropdown-content{
          list-style: none;
          position: relative;
          background: var(--white);

          &:after{
            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid var(--white);
          }
          width: 100%;
          li{
            line-height: 1.5rem;
            font-size: .85rem;

            &:not(:first-child) {
              padding-left: 0;
            }

            a{
              display: flex;
              align-items: center;
              min-width: 160px;
              color: var(--black);
              padding: .4rem;

              .dropdown-icon{
                margin-right: .6rem;
                width: 17px;
                height: 17px;

                span{
                  display: block;

                }

              }

              &:hover{
                opacity: 1;
                background: rgba(193, 0, 96, .5);
                color: var(--white);
              }

              span{
                font-size: .8rem;
              }
            }
          }
        }
      }
    }
  }

  .mobile-nav{
    padding: .6rem 0;
    @media screen and (max-width: 420px) {
      display: flex;
    }

    .user-dropdown-content{
      li{
        a{
          span{
            display: block;
          }
        }
      }
    }

    @media screen and (min-width: 421px) {
      display: none;
    }
  }
}

.detailed-container{
  @media screen and (min-width: 650px) {
    width: 500px;
    padding: 1.5rem;
    margin: 2rem auto;
  }

  @media screen and (max-width: 649px) {
    width: calc(95% - 1rem);
    margin: 1.5rem auto;
    padding: 1rem;
  }
}


.feedback-container{
  position: fixed;
  z-index: 99999;
  width: 300px;
  text-align: center;
  font-size: .85rem;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  transition: ease-in-out .3s;
  color: var(--white);
  padding: .5rem 1rem;

  &.closed{
      top: -100px;
      opacity: 0;

  }

  &.success{
    background: var(--black);
    border: 1px solid rgba(7, 198, 141, .5);
    color: rgba(7, 198, 141, .8);
  }

  &.error {
    border: 1px solid rgba(255, 0, 0, .5);
    background: var(--black);
    color: rgba(255, 0, 0, .8);
  }

  &.open{
    top: 100px;
    opacity: 1;
  }
}


@import "../../color-filters";
body{
  &[data-mode="light"] {
    .main-space{
      background: var(--white);
      .space-header{
        background: var(--white);
        .header-data{
          .main-data{
            .data-row{
              .username{
                color: var(--color-main-dark);
              }
            }
          }
        }
        .chat-option-section{
          .option-dropdown{
            background: var(--white);
            border: 1px solid rgba(0, 0, 0, .15);


            .option-list{
              li{

                /*
                &::before{
                  border-bottom: 8px solid rgba(0, 0, 0, .15);
                }
                */

                &:hover{
                  background: rgba(0, 0, 0, .09);
                }

              }
            }
          }
        }
      }

      .chat-action-section{
        background: var(--white);

        .emoji-picker-section{
          .smiley-face{
            color: var(--color-main-dark);
          }
        }
      }

      .chat-space{
        .chat-box{


          .chat-message{
            &.sender{
              .message-item{
               &:not(.file-item) {
                 background: rgba(119, 1, 58, .9);
               }
              }


            }

            &.receiver{
             .message-item{
              &:not(.file-item) {
                background: rgba(193, 0, 96, .9);
              }
             }
            }
            .message-item{
              color: var(--white);
            }

            .data-item{
              .action-section-data{
                .action-element{
                  .message-actions{
                    background: var(--white);
                    border: 1px solid var(--color-main-light);
                  }
                }
              }
            }

          }
        }
      }
    }
  }

  &[data-mode="dark"] {
    .main-space{
      .space-header{
        background: var(--black);
        .header-data{
          .main-data{
            .data-row{
              .username{
                color: var(--white);
              }
            }
          }
        }
        .chat-option-section{
          .option-dropdown{
            background: var(--black);
            border: 1px solid rgba(255, 255, 255, .15);

            .option-list{

            /*  &::before{
                border-bottom: 8px solid var(--black);
              }*/
              li{
                &:hover{
                  background: rgba(255, 255, 255, .09);
                }
              }
            }
          }
        }
      }

      .chat-action-section{
        background: var(--black);

        .emoji-picker-main{
          aside{
            &.emoji-picker-react{
              background: var(--black);
              border: 1px solid  rgba(193, 0, 96, .29);
              box-shadow: 0 0 4px 2px rgba(193, 0, 96, .89);

              .emoji-categories{
                button{
                  filter: $whiteFilter;
                }
              }

              .emoji-search{
                border: 1px solid rgba(193, 0, 96, .39);
              }

              .emoji-group{
                &:before{
                  background: var(--black);
                }
              }
            }
          }
        }

        .upload-section{
          .attachment-icon{
            filter: $whiteFilter;
          }
        }
      }
    }
  }
}

$spacePadding: .7rem 1.5rem;
.main-space{
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: column;

  .space-header{
    padding: $spacePadding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 4px 2px rgba(224, 26, 122, .29);
    border: 1px solid rgba(119, 1, 58, .19);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;

    .header-data{
      display: flex;
      align-items: center;

      img{
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: .8rem;
      }

      .main-data{
        .data-row{

          .username, .item-status{
            display: inline-block;
            vertical-align: middle;
          }
          .username{
            font-size: .95rem;
            font-weight: 600;
            margin-right: .4rem;
            color: var(--white);
            text-decoration: none;

            &:hover{
              opacity: .7;
            }
          }

          .item-status{
            width: 8px;
            height: 8px;
          }
        }

        .user-status{
          font-size: .8rem;
          opacity: .7;

          &.inactive{
            opacity: .5;
          }

          &.active{
            color: var(--color-success);
            opacity: .8;
          }
        }
      }
    }

   .chat-option-section{
     position: relative;
     .vertical-icons{
       font-size: 20px;
       cursor: pointer;
       margin-top: 12px;
     }

     .option-dropdown{
       position: absolute;
       top: 90%;
       right: 0;
       width: 160px;
       background: var(--white);

       .option-list{
         position: relative;
         list-style: none;
         padding-left: 0;

        /* &::before{
           content: "";
           position: absolute;
           top: -8px;
           right: 10px;
           width: 0;
           height: 0;
           border-left: 8px solid transparent;
           border-right: 8px solid transparent;
           border-bottom: 8px solid var(--white);
         }*/

         li{
           padding: .8rem;
           font-size: .75rem;
           display: flex;
           align-items: center;
           transition: ease .3s;
           cursor: pointer;

           &.error-type{
             color: #ff0000;
           }

           &:hover{
             background: rgba(0, 0, 0, .09);
           }
         }
       }
     }
   }
  }

  .chat-space{
    height: calc(100% - 72px);
    width: 100%;
    white-space: nowrap;

    .chat-box{
      min-height: calc(100% - 60px);

      @media screen and (min-width: 750px) {
        padding: 1.2rem .8rem;
      }

      @media screen and (max-width: 749px) {
        padding: 1.2rem .8rem 4.5rem;
      }


      display: flex;
      flex-direction: column;
      .conversation-starter{
        width: fit-content;
        width: -moz-fit-content;
        max-width: 400px;
        margin: 2rem auto;
        background: var(--color-main-dark);
        color: var(--white);
        font-size: .8rem;
        padding: .6rem 1.5rem;
        white-space: initial;
        text-align: center;
        border-radius: 8px;
        opacity: .75;
      }

      .chat-date{
        text-align: center;
        font-size: 1.05rem;
        font-weight: 700;
        opacity: .6;
        margin-bottom: .8rem;
      }

      .chat-message{
        max-width: 300px;
        width: fit-content;
        width: -moz-fit-content;

        &.receiver{
         .message-item{
           &:not(.file-item){
             background: rgba(193, 0, 96, .5);
           }
         }

          .message-status{
            flex-direction: row-reverse;

            .message-state{
              margin-left: .6rem;
            }
          }
        }

        &.sender{
          .message-item{
            &:not(.file-item) {
              background: rgba(119, 1, 58, .5);
            }
          }

          .message-status{
            margin-left: auto;
            text-align: right;

            .message-state{
              margin-right: .6rem;
            }
          }
          margin-left: auto;
        }

        &.faded{
          opacity: .6;
        }

        &:not(:last-child){
          margin-bottom: .4rem;
        }


        .message-item{
          &:not(.file-item) {
            padding-left: 16px;
            padding-right: 16px;
          }
         padding-top: 6px;
          padding-bottom: 6px;
         font-size: .9rem;
         white-space: initial;
         border-radius: 8px;
         display: flex;
         flex-direction: column;

          .media-element{
            width: 100%;
            max-height: 275px;
            position: relative;
            .picture-element, .video-player{
              width: 100%;
              height: 100%;
            }
          }
       }

        .data-item{
          align-items: center;

          &:hover{
            .action-section-data{
              display: block;
            }
          }


          .action-section-data{
            display: none;
            .action-element{
              position: relative;
              height: 100%;

              .vertical-dots{
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
                transition: ease-in-out .2s;

                &:hover{
                  opacity: .7;
                }
              }

              .message-actions{
                position: absolute;
                top: -40px;
                right: 8px;
                background: var(--black);
                z-index: 99;
                border-radius: 8px;
                transition: ease-in-out .2s;

                ul{
                  padding-left: 0;
                  list-style: none;
                }

                &.open{
                  transform: scale(1);
                }

                &.closed{
                  transform: scale(0);
                }

                li{
                  padding: .4rem .75rem;
                  font-size: .75rem;
                  display: flex;
                  align-items: center;
                  max-width: 180px;
                  min-width: 120px;
                  transition: ease-in-out .2s;

                  &:hover{
                    cursor: pointer;
                    background: rgba(224, 26, 122, .5);
                  }


                  &.error{
                    color: var(--color-error);
                  }

                  svg{
                    font-size:16px;
                    margin-right: .4rem;
                  }

                  span{
                    white-space: initial;
                  }
                }

                .edit-input{
                  padding: .3rem .7rem;
                  width: 200px;

                  input{
                    font-size: .75rem;
                  }

                  .button-row{
                    button{
                      padding: .25rem .75rem;
                      font-size: .65rem;
                      border-radius: 16px;

                      &:first-child{
                        margin-right: .6rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }


      .message-status{
        display: flex;
        align-items: center;
        width: fit-content;
        width: -moz-fit-content;

        .message-state{
          font-size: .65rem;
          color: var(--color-main-normal);
        }
        .message-time{
          font-size: .7rem;
        }
      }
    }
  }
  .chat-action-section{
    background: var(--black);

    padding: $spacePadding;
    z-index: 1;
    bottom: 0;

    @media screen and (min-width: 750px) {
      position: sticky;
      left: 20px;

    }
    @media screen and (max-width: 749px) {
      position: fixed;
      bottom: -2px;
      left: 0;
      width: 100%;
    }

    .actionable-items{
      display: flex;
      align-items: center;
    }

    .typing-notification{
      font-size: .75rem;
      opacity: .7;
    }

    .upload-section{

      input{
        display: none;
      }
      .attachment-icon{
        width: 20px;
        height: 20px;
        transition: ease-in-out .3s;
        margin-top: 8px;
        cursor: pointer;

        &:hover{
          transform: scale(1.1);
        }
      }
    }

    .input-control{
      flex-grow: 100;
      margin: 0 20px;
      position: relative;

      .recorder-section, .emoji-picker-section{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .recorder-section{
        right: 16px;
        padding-top: 8px;


        .microphone{
          font-size: 16px;
        }
      }

      .emoji-picker-section{
        left: 16px;
        color: #f5f5f5;

        .main-picker{
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;

          .smiley-face{
            transition: ease-in-out .3s;

            &:hover{
              transform: scale(1.05);
              opacity: .6;
              cursor: pointer;
            }
          }


          .emoji-picker-main{
            position: absolute;
            top: -330px;
           left: -100%;
            z-index: -1;
            transition: all .4s;
            opacity: 0;


            &.open{
              top: -330px;
              opacity: 1;
              display: block;
              animation: translateUp .5s;
            }


            &.closed{
              top: 0;
              opacity: 0;
              animation: translateDown .5s;
              display: none;
            }
          }
        }
      }

      input{
        padding: 12px 40px;
        border-radius: 16px;
      }
    }
    .air-button{
      padding: .4rem .8rem;
      background: var(--color-main-dark);
      color: var(--white);
      border: 1px solid transparent;
      border-radius: 8px;
      margin-top: 4px;
      transition: ease-in-out .3s;

      &:hover{
        cursor: pointer;
        opacity: .6;
      }

      .air-icon{
        font-size: 16px;
      }
    }
  }
}

.space-error{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .reset{
    color: var(--color-error);
    font-weight: 600;
    cursor: pointer;
    transition: ease-in-out .3s;

    &:hover{
      opacity: .7;
    }
  }
}

@keyframes translateUp {
  from{ top: 330px; opacity: 0}
  to{ top: -330px; opacity: 1}
}

@keyframes translateDown {
  from{ top: -330px; opacity: 1}
  to{ top: 330px; opacity: 0; display: none}
}


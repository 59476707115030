@import "media-element";
@import "../animations";
body{
  &[data-mode="light"] {
    .audio-element{
      .voice-player{
        .control-button{
          svg{
            color: var(--black);
            opacity: .8;
          }
        }
      }

      .media-placeholder{
        .audio-duration{
          color: var(--color-main-dark);
        }
      }
    }
  }
}
.audio-element{
  audio{
    width: 0;
    height: 0;
  }

  .media-placeholder{
    &.audio-container{
      position: relative;
      overflow-y: hidden;

      svg{
        &:first-child{
          margin-top: -20px;
        }
      }

      .audio-controls{
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        padding: .2rem .4rem;
        z-index: 1;
        background: #1e0310;
        border-radius: 0 0 12px 12px;
        animation: translateUpwards .3s;

        .audio-times{
          font-size: .65rem;
          color: var(--white);
        }

        .control-row{
          display: flex;
          position: relative;

          .control-items{
            margin: 0 auto;
            .control-button{
              cursor: pointer;

              &:hover{
                transform: scale(1.2);
              }

              svg{
                font-size: 20px;
                color: var(--white);
                opacity: 1;
              }
            }
          }

          .download-button{
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;

            &:hover{
              opacity: .6;
            }
            svg{
              font-size: 15px;
              color: var(--white);
              opacity: 1;
            }
          }
        }
      }
    }

    .voice-player{
      .control-button{
        margin-right: .8rem;
        padding-top: .6rem;
        cursor: pointer;

        svg{
          font-size: 20px;

          &:hover{
            opacity: 1;
          }
        }
      }

      .slider-section{
        flex-grow: 100;
      }
    }

    .audio-duration{
      font-size: .65rem;
      text-align: right;
    }
  }

}

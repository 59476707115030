body{
  &[data-mode="dark"] {
    .progress-bg{
      background: rgba(119, 1, 58, .2);
    }
  }

  &[data-mode="light"] {
    .progress-bg{
      background:  rgba(193, 0, 96, .2);
    }
  }
}
.progress-bar{
  .progress-text{
    font-size: .95rem;
    text-align: center;
    margin-bottom: .75rem;
  }
  .progress-bg{
    width: 100%;
    position: relative;
    height: 4px;
    border-radius: 4px;
    background: rgba(119, 1, 58, .2);

    .progress-line{
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: inherit;
      background: var(--color-main-light);
      transition: width .5s;
    }
  }
}

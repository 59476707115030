@import "../base";
body{
  &[data-mode="light"] {
    $initialBg: rgba(0, 0, 0, .3);
    .placeholder-items{
      .position-item{
        .image-placeholder{
          background: $initialBg;
        }

        .user-placeholder{
          background: $initialBg;
        }

        .initial-placeholder{
          span{
            background: $initialBg;
          }
        }

        .initial-list {
          span{
            background: $initialBg;
          }
        }
      }
    }
  }

  &[data-mode="dark"] {
    $darkInitialBg: rgba(255, 255, 255, .3);
    .placeholder-items{
      .position-item{
        .image-placeholder{
          background: $darkInitialBg;
        }

        .user-placeholder{
          background: $darkInitialBg;
        }

        .initial-placeholder{
          span{
            background: $darkInitialBg;
          }
        }
      }

      .initial-list {
        span{
          background: $darkInitialBg;
        }
      }
    }
  }
}

.placeholder-items{
  padding: 2rem 0;
  position: relative;
  .position-item{
    &:not(:last-child){
      margin-bottom: 1.2rem;
    }

    .initial-placeholder{
      padding: 1.2rem;
      span{
       background: rgba(0, 0, 0, .3);
        width: 10px;
        height: 10px;
        display: block;
      }
    }

    .image-placeholder{
      width: 60px;
      height: 60px;
      background: rgba(0, 0, 0, .3);
      margin-right: 1rem;
      border-radius: 50%;
      animation: fadeItem 3s linear infinite;
    }

    .user-placeholder{
      background: rgba(0, 0, 0, .3);
      animation: fadeItem 3s linear infinite;
      height: 10px;
      border-radius: 10px;


      @media screen and (min-width: 1024px) {
        width: 25%;
      }

      @media screen and (min-width: 650px) and (max-width: 1023px) {
        width: 65%;
      }

      @media screen and (max-width: 649px) {
        width: 50%;
      }
    }

  }

  .initial-list {
    position: absolute;
    top: 100px;
    right: 0;
    span{
      display: block;
      width: 10px;
      height: 10px;
      background: rgba(0, 0, 0, .3);
      animation: fadeItem 3s linear infinite;

      &:not(:last-child) {
        margin-bottom: .8rem;
      }
    }
  }
}

body{
  &[data-mode="dark"] {
    .list-container{
      border-right: 1px solid rgba(193, 0, 96, .2);
      border-top: 1px solid rgba(193, 0, 96, .2);
    }
  }
}

.space-view{
  width: 100%;
  display: flex;
  height: calc(100vh - 65px);
  overflow: hidden;
  margin-top: -15px;

  .list-container{
    height: 100%;
    overflow-y: hidden;
    @media screen and (min-width: 750px) {
      display: block;
      width: 320px;
    }

    @media screen and (max-width: 749px) {
      display: none;
    }

    .search-bar-container{
      margin-top: 1rem;
    }
  }

  .main-space{
    height: 100%;
    @media screen and (min-width: 750px) {
      width: calc(100% - 320px);
    }

    @media screen and (max-width: 749px) {
      width: 100%;
    }
  }
}

@import "../../base";

body{
  &[data-mode="dark"] {
    $itemBg: rgba(255, 255, 255, .15);
    .contact-container{
      .image-placeholder,
      .name-placeholder,
      .address-placeholder,
      .icon-placeholder,
      .list-main-placeholder,
      .list-sub-placeholder{
        background: $itemBg;
      }
    }

  }

  &[data-mode="light"] {
    $itemBg: rgba(0, 0, 0, .3);
    .contact-container{
      background: var(--white);
      border-radius: 20px;
      min-height: 400px;
      .image-placeholder,
      .name-placeholder,
      .address-placeholder,
      .icon-placeholder,
      .list-main-placeholder,
      .list-sub-placeholder{
        background: $itemBg;
      }
    }

  }
}


$itemBg:  rgba(0, 0, 0, .6);
.contact-container{
  margin: 2rem auto;
  padding: 1.2rem;
  @media screen and (min-width: 650px) {
    width: 500px;
  }

  @media screen and (max-width: 649px) {
    width: calc(95% - 1.5rem);
  }

  .image-placeholder{
    width: 80px;
    height: 80px;
    background: $itemBg;
    border-radius: 50%;
    margin: 0 auto 2rem;
  }

  .name-placeholder{
    background: $itemBg;
    @media screen and (min-width: 650px) {
      width: 100px;
    }

    @media screen and (max-width: 649px) {
      width: calc(85% - 1.5rem);
    }

  }

  .address-placeholder{
    background: $itemBg;
    @media screen and (min-width: 650px) {
      width: 300px;
    }

    @media screen and (max-width: 649px) {
      width: calc(90% - 1.5rem);
    }

  }

  .name-placeholder,.address-placeholder{
    height: 4px;
    border-radius: 8px;
    margin: 0 auto 1.2rem;
  }

  .details-placeholder{
    margin: 2rem 0;
  }

  .item-placeholder{
    padding: .8rem 0;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .icon-placeholder{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
    background: $itemBg;

    &.contact-icon{
      margin-left: auto;
    }
  }

  .list-main-placeholder, .list-sub-placeholder{
    height: 3px;
    border: 6px;
    background: $itemBg;
  }

  .list-main-placeholder{
    width: 100px;
    background: $itemBg;
    margin-bottom: 1.2rem;
  }

  .list-sub-placeholder{
    width: 80px;
    background: $itemBg;
  }

  .image-placeholder,
  .name-placeholder,
  .address-placeholder,
  .icon-placeholder,
  .list-main-placeholder,
  .list-sub-placeholder{
    animation: fadeItem 3s linear infinite;
  }
}

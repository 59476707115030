@keyframes fadeItem {
  0%{opacity: .2}
  25%{opacity: 1}
  50%{opacity: .2}
  75%{opacity: 1}
  100%{opacity: .2}
}
@-webkit-keyframes fadeItem {
  0%{opacity: .2}
  25%{opacity: 1}
  50%{opacity: .2}
  75%{opacity: 1}
  100%{opacity: .2}
}

@-moz-keyframes fadeItem {
  0%{opacity: .2}
  25%{opacity: 1}
  50%{opacity: .2}
  75%{opacity: 1}
  100%{opacity: .2}
}

@-o-keyframes fadeItem {
  0%{opacity: .2}
  25%{opacity: 1}
  50%{opacity: .2}
  75%{opacity: 1}
  100%{opacity: .2}
}

@keyframes slideUp {
  from{transform: translateY(80px); opacity: 0}
  to{transform: translateY(0); opacity: 1}
}


@-webkit-keyframes slideUp {
  from{transform: translateY(80px); opacity: 0}
  to{transform: translateY(0); opacity: 1}
}


@-moz-keyframes slideUp {
  from{transform: translateY(80px); opacity: 0}
  to{transform: translateY(0); opacity: 1}
}


@-o-keyframes slideUp {
  from{transform: translateY(80px); opacity: 0}
  to{transform: translateY(0); opacity: 1}
}


@keyframes translateUpwards {
  from {transform: translateY(100%); opacity: 0}
  to{transform: translateY(0); opacity: 1}
}

@-webkit-keyframes translateUpwards {
  from {transform: translateY(100%); opacity: 0}
  to{transform: translateY(0); opacity: 1}
}

@-moz-keyframes translateUpwards {
  from {transform: translateY(100%); opacity: 0}
  to{transform: translateY(0); opacity: 1}
}

@-o-keyframes translateUpwards {
  from {transform: translateY(100%); opacity: 0}
  to{transform: translateY(0); opacity: 1}
}

@keyframes scaleItem{
  0%{ transform: scale(1) }
  50%{ transform: scale(1.5) }
  100%{ transform: scale(1) }
}

@-webkit-keyframes scaleItem{
  0%{ transform: scale(1) }
  50%{ transform: scale(1.5) }
  100%{ transform: scale(1) }
}

@-o-keyframes scaleItem{
  0%{ transform: scale(1) }
  50%{ transform: scale(1.5) }
  100%{ transform: scale(1) }
}

@-moz-keyframes scaleItem{
  0%{ transform: scale(1) }
  50%{ transform: scale(1.5) }
  100%{ transform: scale(1) }
}

.search-bar-container{
  &.fixed-container{
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
    padding-top: 1.35rem;
    background: var(--white);
    z-index: 99;
    animation: toFixedPosition .3s;

    background: var(--black);

    @media screen and (max-width: 650px) {
      .search-bar{
        width: calc(90% - 1.5rem);
      }
    }

    @media screen and (min-width: 651px) and (max-width: 1024px) {
      .search-bar{
        width: calc(80% - 2rem);
      }
    }

    @media screen and (max-width: 1024px) {
      .search-bar{
        margin: 0 auto;
      }
    }

    @media screen and (min-width: 1024px) {
      .search-bar{
        width: calc(50% - 2.5rem);
      }
    }

    @keyframes toFixedPosition {
      from{z-index: -1; opacity: 0; top: 0}
      to{z-index: 99; opacity: 1; top: 65px}
    }

    @-webkit-keyframes toFixedPosition {
      from{z-index: -1; opacity: 0; top: 0}
      to{z-index: 99; opacity: 1; top: 65px}
    }

    @-moz-keyframes toFixedPosition {
      from{z-index: -1; opacity: 0; top: 0}
      to{z-index: 99; opacity: 1; top: 65px}
    }

    @-o-keyframes toFixedPosition {
      from{z-index: -1; opacity: 0; top: 0}
      to{z-index: 99; opacity: 1; top: 65px}
    }
  }

  @media screen and (min-width: 750px) and (max-width: 1024px) {
    width: calc(90% - 2rem);
    margin-left: auto;
    margin-right: auto;
  }

  .search-bar{
    position: relative;

    .search-icon{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      width: 18px;
      height: 18px;
      color: var(--color-main-light);
    }

    input{
      margin-top: 0;
      padding: 8px 40px;
    }
  }

  .contact-tabs{
    display: flex;
    margin: 1.5rem auto 0;
    width: fit-content;
    width: -moz-fit-content;


    .tab-item{
      min-width: 56px;
      height: 32px;
      font-size: .9rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: ease-in-out .3s;
      cursor: pointer;

      &:hover{
        color: var(--color-main-normal);
        opacity: .7;
      }

      &:not(:last-child){
        margin-right: .6rem;
      }

      .aligned-item{
        display: flex;
        align-items: center;
      }

      .border-line{
        display: block;
        width: 0;
        height: 2px;
        background: var(--color-main-normal);
        margin-top: auto;
        transition: ease .3s;
      }

      &.active-tab{
        filter: brightness(0) saturate(100%) invert(8%) sepia(98%) saturate(4855%) hue-rotate(321deg) brightness(116%) contrast(104%);

        .border-line{
          width: 100%;
        }
      }

      .favorite-icon{
        width: 16px;
        height: 16px;
        margin-right: .4rem;
      }
    }
  }
}

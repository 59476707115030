@import "../color-filters";

body{
  &[data-mode="dark"] {
    .microphone{
      color: var(--white);
    }
    .media-options{
      background: var(--black);

      .option-items{

        &::before{
          border-top: 8px solid var(--black);
        }
        .recorder-option{
          &.pause-option,
          &.play-option{
            color: var(--white);
          }
        }
      }
    }
  }
  &[data-mode="light"] {
    .microphone{
      color: var(--black);
    }
    .media-options{
      background: var(--white);

      .option-items{

        &::before{
          border-top: 8px solid var(--white);
        }
        .recorder-option{
          &.pause-option,
          &.play-option{
            color: var(--black);
          }
        }
      }
    }

  }
}

.media-recorder{
  position: relative;

  .mic-holder{
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: transparent;
  }
  .microphone{
    color: white;
    font-size: 28px;
    cursor: pointer;
    transition: ease-in-out .3s;

    &:hover{
      opacity: .7;
    }
  }

  .media-options{
    width: 100px;
    position: absolute;
    top: -40px;
    background: var(--black);
    left: -50px;
    transition: all .3s;

    &.open{
      transform: scale(1);
    }

    &.closed{
      transform: scale(0);
    }

    .option-items{
      width: 100%;
      padding: .3rem .6rem;
      display: flex;
      justify-content: center;
      border-radius: 8px;
      position: relative;

      &::before{
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-top: 8px solid var(--white);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }

      .recorder-option{
        font-size: 18px;
        transition: ease-in-out .3s;
        cursor: pointer;
        &:hover{
          opacity: .6;
        }
        &:not(:last-child) {
          margin-right: .8rem;
        }

        &.pause-option,
        &.play-option{
          color: var(--white);
        }

        &.cancel-option{
          filter: $whiteFilter;
        }

        &.pause-option{
          font-size: 19px;
        }
        &.play-option{
          font-size: 15px;
        }



        &.stop-option{
          color: var(--color-error);
        }
      }
    }
  }
}

.animate-recording{
  animation: recordingAnimation 2s linear infinite;
}

@keyframes recordingAnimation{
  0%{
    background: rgba(224, 26, 122, .15);
  }

  25%{
    background: rgba(224, 26, 122, .3);
  }

  50%{
    background: rgba(224, 26, 122, .5);
  }

  75%{
    background: rgba(224, 26, 122, .3);
  }

  100%{
    background: rgba(224, 26, 122, .15);
  }

}

body{
  &[data-mode="dark"] {
    .main-view{
      .list-container{
        border-right: 1px solid transparent;
        border-top: 1px solid transparent;
      }
    }
  }

  &[data-mode="light"] {
    .main-view{
      .list-container{
        border-right: 1px solid transparent;
        border-top: 1px solid transparent;
      }
    }
  }
}

.main-view{
  max-width: 1100px;
  padding: 30px 0;
  @media screen and (min-width: 1024px) {
    width: calc(65% - 3.5rem);
  }

  @media screen and (min-width: 750px) and (max-width: 1023px) {
    width: calc(85% - 2.5rem);
  }

  @media screen and (max-width: 749px) {
    width: calc(100% - 1.5rem);

    .list-container{
      display: block;
    }
  }

  .list-container{
    width: 100%;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;

    .list-heading{
      line-height: 3.6rem;
    }
  }
}

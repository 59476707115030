body{
  &[data-mode="light"] {
    .file-downloader{
      background: rgba(0, 0, 0, .6);
    }

    .file-image{
      background: #f2f2f2;

      .file-extension{
        color: var(--black);
      }
    }


  }
}

.file-downloader{
  width: 250px;
  padding: .6rem 1rem;
  background: var(--black);
  border: 1px solid rgba(193, 0, 96, .2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;

  .file-image{
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #2b2b2b;
    border-radius: 8px;

    .file-icon{
      width: 36px;
      height: 36px;
    }
    .file-extension{
      text-transform: uppercase;
      font-size: 1.35rem;
      font-weight: 600;
      color: var(--white);
      opacity: .6;
      margin-top: .4rem;
    }

  }

  .file-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: .75rem;

    .file-name{
      max-width: 85%;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--white);
      font-size: .95rem;
    }

    .download-icon{
      font-size: 16px;
      color: var(--white);
      &:hover{
        opacity: .6;
        cursor: pointer;
      }
    }
  }
}
